.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #f5f5f5;
  font-size: 12px;
  line-height: 1.33333333;
  font-family: inherit;
  cursor: pointer;
  color: #4e8fcc;
  padding: 10px 24px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
}
.button:hover,
.button:focus {
  background-color: #4e8fcc;
  color: #fff;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 900px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.mainnavi div.sub2 {
  width: 100%;
}
.mainnavi div.sub2 div.sub3 {
  display: none !important;
}
.mainnavi div.sub2 > .item {
  margin-right: 24px;
}
.mainnavi div.sub2 > .item.exit {
  margin-right: 0 !important;
}
.mainnavi div.sub2 > .item > .menu {
  color: #464646;
  padding: 28px 0;
  background-size: 0 12px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #4e8fcc, #4e8fcc);
  transition: all 0.24s;
}
.mainnavi div.sub2 > .item > .menu:hover,
.mainnavi div.sub2 > .item > .menu:focus,
.mainnavi div.sub2 > .item > .menu.path {
  color: #4e8fcc;
  background-size: 100% 12px;
}
.subnavi div.sub3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.subnavi div.sub3 div.sub4 {
  display: none !important;
}
.subnavi div.sub3 > .item {
  margin-bottom: 8px;
}
.subnavi div.sub3 > .item.exit {
  margin-bottom: 0 !important;
}
.subnavi div.sub3 > .item > .menu {
  color: #464646;
}
.subnavi div.sub3 > .item > .menu:hover,
.subnavi div.sub3 > .item > .menu:focus,
.subnavi div.sub3 > .item > .menu.path {
  color: #4e8fcc;
}
.subsubnavi div.sub4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.subsubnavi div.sub4 div.sub4 {
  display: none !important;
}
.subsubnavi div.sub4 > .item {
  margin-bottom: 8px;
}
.subsubnavi div.sub4 > .item.init {
  margin-top: var(--subSectionSpace);
}
.subsubnavi div.sub4 > .item.exit {
  margin-bottom: 0 !important;
}
.subsubnavi div.sub4 > .item > .menu {
  color: #464646;
}
.subsubnavi div.sub4 > .item > .menu:hover,
.subsubnavi div.sub4 > .item > .menu:focus,
.subsubnavi div.sub4 > .item > .menu.path {
  color: #4e8fcc;
}
.section--mainContent .segment.segment--1 {
  width: 168px;
}
.section--mainContent .segment.segment--2 {
  width: calc(100% - 192px);
}
/*# sourceMappingURL=./screen-large.css.map */